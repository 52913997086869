<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <v-container>
        <h1>
          <span>Контрагенты</span>
          <div>
            <v-btn class="add-link" @click="$router.push('/counterpartyEdit/')">Добавить контрагента</v-btn>
          </div>
        </h1>
        <Register :config="regConfig" ref="registerComponent"></Register>
      </v-container>
    </transition>
  </div>
</template>
<script>
import {counterpartiesRegisterConfig} from '../register/RegisterConfigs';
import Register from '../register/Register.vue';

export default {
  name: "CounterpartiesComponent",
  props: {},
  data() {
    return {
      regConfig: counterpartiesRegisterConfig
    };
  },
  beforeMount() {
  },
  components: {Register},
}
</script>

<style lang="scss">
@import "../../styles/main.scss";
</style>